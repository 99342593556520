import { Content, asImageSrc, asLink } from '@prismicio/client';
import { PrismicNextImage, PrismicNextLink } from '@prismicio/next';
import { SliceComponentProps } from '@prismicio/react';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { CommonButton } from 'ui/components/CommonButton';
import { CtaLink } from 'ui/components/CtaLink';
import { Carousel } from 'ui/components/Carousel';
import HeroLight from 'ui/components/Hero/HeroLight';
import { PrismicRichText } from 'ui/components/PrismicRichText';
import { DesktopBreakpoint, ResponsiveBreakpoint } from 'ui/components/ResponsiveImage';
import { useWindowsSize } from 'ui/hooks/useWindowSize';
import { SVGLibrary } from 'ui/svgs/library';
import Link from 'ui/components/Link';
import ChevronRight from 'ui/svgs/library/ChevronRight';
import { format } from 'date-fns';
import { useRouter } from 'next/router';
import { normalizeBlogs } from './hero.helper';

const FIXED_AUTHOR_IMG_FILE_NAME = 'ZiaJ5_Pdc1huKrn3_1-13.png';
/**
 * Props for `Hero`.
 */
export type HeroProps = SliceComponentProps<Content.HeroSlice>;

/**
 * Component for "Hero" Slices.
 */
const Hero = ({ slice, index, slices, context }: HeroProps): JSX.Element => {
  const { variation, primary, slice_type, items } = slice;
  const [width] = useWindowsSize();

  if (variation === 'dark') {
    const {
      background_image,
      tablet_background_image,
      mobile_background_image,
      heading,
      description,
      primary_cta_text,
      primary_cta_action,
      primary_cta_url,
      primary_cta_target,
      secondary_cta_text,
      secondary_cta_action,
      secondary_cta_target,
      secondary_cta_url,
      eyebrow_text,
      cta_description,
      text_theme,
      logos,
      column_split_size
    } = primary;
    const { title, logos: items } = (logos as any)?.data || {};

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [heroBackground, setHeroBackground] = useState<string>(background_image?.url || '');

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      if (!isNaN(width)) {
        if (width < ResponsiveBreakpoint) {
          setHeroBackground(
            mobile_background_image?.url || tablet_background_image?.url || background_image?.url || '',
          );
        } else if (width < DesktopBreakpoint) {
          setHeroBackground(tablet_background_image?.url || background_image?.url || '');
        } else {
          setHeroBackground(background_image?.url || '');
        }
      }
      const logoSlidercontainer = document.querySelector('#hero-logos-slider-container') as HTMLElement;
      const backdropContainer = document.getElementById('backgrop-logos-hero-dark') as HTMLElement;
      if (logoSlidercontainer) {
        const computeHeightForBackdrop = window.getComputedStyle(logoSlidercontainer, null).getPropertyValue('height');
        if (backdropContainer) {
          backdropContainer.style.height = `${computeHeightForBackdrop}`;
        }
      }
    }, [width, background_image, tablet_background_image, mobile_background_image]);

    const getButtonProps = (action: string, url: any, target: string) => {
      return {
        ...(action === 'Get Started Overlay' ? { onClick: () => null } : { href: asLink(url), target: target }),
      };
    };
    return (
      <section
        className="Hero-Dark wide-container [ relative overflow-hidden w-full ][ bg-cover bg-center bg-no-repeat bg-gray-0 ]"
        data-slice-type={slice_type}
        data-slice-variation={variation}
        style={{
          backgroundImage: `url("${heroBackground}")`,
          backgroundPositionX: items && items.length > 0 && width < DesktopBreakpoint ? '60%' : 'center',
        }}
        id="hero-dark"
      >
        <div className={clsx("[ grid grid-cols-1 md:grid-cols-3-1 lg:grid-cols-1.5-1 ][ py-36 px-5 md:py-32 md:px-16 lg:py-40 ]", {
          '[ lg:grid-cols-2 ]' : column_split_size === '50_50'
        })}>
          <div>
            {eyebrow_text &&
              <h2
                className={clsx("[ text-16 font-medium ][ mb-4 ]", {
                  '[ text-gray-10 ]' : text_theme === 'dark',
                  '[ text-white ]' : text_theme === 'light'
                })}
              >{eyebrow_text}</h2>
            }
            <h1
              className={clsx("[ text-30 md:text-60 font-medium tracking-tight leading-39 lg:leading-84 ][ mb-6 ]", {
                '[ text-gray-10 ]' : text_theme === 'dark',
                '[ text-white ]' : text_theme === 'light'
              })}
              data-testid="heading"
            >
              {heading}
            </h1>
            <div
              className={clsx("[ mb-6 md:mb-14 ][ text-14 md:text-20 ]", {
                '[ [&_p]:text-gray-10 ]' : text_theme === 'dark',
                '[ [&_p]:text-white ]' : text_theme === 'light'
              })}
            >
              <PrismicRichText
                field={description}
                applyStyles={false}
                boldHighlight="primary"
              />
            </div>
            {primary_cta_text && (
              <CommonButton
                color="blue"
                size="medium"
                {...getButtonProps(primary_cta_action, primary_cta_url, primary_cta_target)}
                className="[ w-full md:w-auto ][ mb-4 mr-4 ]"
              >
                {primary_cta_text}
              </CommonButton>
            )}
            {secondary_cta_text && (
              <CommonButton
                appearance="outline"
                color="white"
                size="medium"
                {...getButtonProps(secondary_cta_action, secondary_cta_url, secondary_cta_target)}
                className="[ w-full md:w-auto ][ mb-4 mr-4 ]"
              >
                {secondary_cta_text}
              </CommonButton>
            )}
            {cta_description && (
              <h2
                className={clsx("[ text-14 ][ mb-4 ]", {
                  '[ text-gray-10 ]' : text_theme === 'dark',
                  '[ text-white ]' : text_theme === 'light'
                })}
              >{cta_description}</h2>
            )}
          </div>
        </div>
        {items && items.length > 0 && (
          <>
            <div id="backgrop-logos-hero-dark" className="wide-container [ absolute left-0 bottom-0 ][ w-full ][ bg-gradient-to-b from-gray-0/0 to-gray-0/90 ]"></div>
            <div className="[ relative overflow-hidden ][ w-screen max-w-9xl ][ pb-16 lg:pt-10 2xl:pb-24 ]" id="hero-logos-slider-container">
              {title && 
                <h4
                  className={clsx("[ text-20 ][ pl-5 mb-6 md:pl-16 md:mb-12 ]", {
                    '[ text-gray-10 ]' : text_theme === 'dark',
                    '[ text-white ]' : text_theme === 'light'
                  })}
                  data-testid="heading"
                >{title}</h4>
              }
              <div className="[ pl-5 md:pl-16 ]">
                <Carousel
                  slideSize={256}
                  slideGap="lg"
                  loop={true}
                  autoPlay
                  autoPlayDelay={2000}
                  speed={0.2}
                  withControls={false}
                  withIndicators={false}
                  className="[ relative overflow-hidden ]"
                >
                  {(items || []).map((logo, index) => (
                    <div key={index} className="[ flex items-center justify-center ][ pr-20 ]">
                      {logo?.inline_svg ? (
                        <SVGLibrary name={logo?.inline_svg} />
                      ) : (
                        <PrismicNextImage className="[ max-w-inherit ]" height={40} field={logo?.logo} />
                      )}
                    </div>
                  ))}
                </Carousel>
              </div>
            </div>
          </>
        )}
      </section>
    );
  }

  if (variation === 'darkOnlyHomePageWithLogo') {
    const {
      background_image,
      tablet_background_image,
      mobile_background_image,
      heading,
      description,
      primary_cta_text,
      primary_cta_action,
      primary_cta_url,
      primary_cta_target,
      secondary_cta_text,
      secondary_cta_action,
      secondary_cta_target,
      secondary_cta_url,
      eyebrow_text,
      cta_description,
      text_theme,
      logos,
      column_split_size
    } = primary;
    const { title, logos: items } = (logos as any)?.data || {};

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [heroBackground, setHeroBackground] = useState<string>(background_image?.url || '');

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      if (!isNaN(width)) {
        if (width < ResponsiveBreakpoint) {
          setHeroBackground(
            mobile_background_image?.url || tablet_background_image?.url || background_image?.url || '',
          );
        } else if (width < DesktopBreakpoint) {
          setHeroBackground(tablet_background_image?.url || background_image?.url || '');
        } else {
          setHeroBackground(background_image?.url || '');
        }
      }
      const logoSlidercontainer = document.querySelector('#hero-logos-slider-container') as HTMLElement;
      const backdropContainer = document.getElementById('backgrop-logos-hero-dark') as HTMLElement;
      if (logoSlidercontainer) {
        const computeHeightForBackdrop = window.getComputedStyle(logoSlidercontainer, null).getPropertyValue('height');
        if (backdropContainer) {
          backdropContainer.style.height = `${computeHeightForBackdrop}`;
        }
      }
    }, [width, background_image, tablet_background_image, mobile_background_image]);

    const getButtonProps = (action: string, url: any, target: string) => {
      return {
        ...(action === 'Get Started Overlay' ? { onClick: () => null } : { href: asLink(url), target: target }),
      };
    };
    return (
      <section
        className="Hero-DarkHomeLogo wide-container [ relative overflow-hidden w-full ][ bg-cover bg-center bg-no-repeat bg-gray-0 ]"
        data-slice-type={slice_type}
        data-slice-variation={variation}
        style={{
          backgroundImage: `url("${heroBackground}")`,
          backgroundPositionX: items && items.length > 0 && width < DesktopBreakpoint ? '45%' : 'center',
        }}
        id="hero-dark"
      >
        <div className="[ grid grid-cols-1 md:grid-cols-12 ][ pt-60 px-5 md:pt-[410px] lg:pt-[398px] md:px-16 md:pb-10 ]">
          <div className={clsx('[ md:col-span-9 ][ pt-8 md:pt-0 ]', {
            'lg:col-span-6' : column_split_size === '50_50',
            'lg:col-span-8' : column_split_size === '60_40'
          })}>
            {eyebrow_text &&
              <h2 className={clsx("[ text-16 font-medium ][ mb-4 ]", {
                '[ text-gray-10 ]' : text_theme === 'dark',
                '[ text-white ]' : text_theme === 'light'
              })}>{eyebrow_text}</h2>
            }
            <h1 className={clsx("[ text-30 md:text-60 font-medium tracking-tight leading-tight ][ mb-6 ][ max-w-[560px] ]", {
                '[ text-gray-10 ]' : text_theme === 'dark',
                '[ text-white ]' : text_theme === 'light'
              })} data-testid="heading">
              {heading}
            </h1>
            <div className={clsx("[ mb-6 md:mb-14 ][ text-14 md:text-20 ]", {
              '[ text-gray-10 ]' : text_theme === 'dark',
              '[ text-white ]' : text_theme === 'light'
            })}>
              <PrismicRichText
                field={description}
                applyStyles={false}
                boldHighlight="primary"
              />
            </div>
            {primary_cta_text && (
              <CommonButton
                color="white"
                size="medium"
                {...getButtonProps(primary_cta_action, primary_cta_url, primary_cta_target)}
                className="[ w-full md:w-auto ][ mb-4 mr-4 ]"
              >
                {primary_cta_text}
              </CommonButton>
            )}
            {secondary_cta_text && (
              <CommonButton
                appearance="outline"
                color="white"
                size="medium"
                {...getButtonProps(secondary_cta_action, secondary_cta_url, secondary_cta_target)}
                className="[ w-full md:w-auto ][ mb-4 mr-4 ]"
              >
                {secondary_cta_text}
              </CommonButton>
            )}
            {cta_description && (
              <h2 className={clsx("[ text-14 ][ mb-4 ]", {
                '[ text-gray-10 ]' : text_theme === 'dark',
                '[ text-white ]' : text_theme === 'light'
              })}>{cta_description}</h2>
            )}
          </div>
        </div>
        {items && items.length > 0 && (
          <>
            <div id="backgrop-logos-hero-dark" className="wide-container [ absolute left-0 bottom-0 ][ w-full ][ bg-gradient-to-b from-gray-0/0 to-gray-0/90 ]"></div>
            <div className="[ relative overflow-hidden ][ w-screen max-w-9xl ][ pt-24 pb-16 ]" id="hero-logos-slider-container">
              {false &&
                <h4 className={clsx("[ text-20 ][ pl-5 mb-6 md:pl-16 md:mb-12 ]", {
                  '[ text-gray-10 ]' : text_theme === 'dark',
                  '[ text-white ]' : text_theme === 'light'
                })} data-testid="heading">
                  {title}
                </h4>
              }
              <div className="[ pl-5 md:pl-16 ]">
                <Carousel
                  slideSize={256}
                  slideGap="lg"
                  loop={true}
                  autoPlay
                  autoPlayDelay={2000}
                  speed={0.2}
                  withControls={false}
                  withIndicators={false}
                  className="[ relative overflow-hidden ]"
                >
                  {(items || []).map((logo, index) => (
                    <div key={index} className="[ flex items-center justify-center ][ pr-20 ]">
                      {logo?.inline_svg ? (
                        <SVGLibrary name={logo?.inline_svg} />
                      ) : (
                        <PrismicNextImage height={40} className="[ max-w-inherit ]" field={logo?.logo} />
                      )}
                    </div>
                  ))}
                </Carousel>
              </div>
            </div>
          </>
        )}
      </section>
    );
  }

  if (variation === 'centered') {
    const {
      heading,
      description,
      primary_cta_text,
      primary_cta_url,
      primary_cta_target,
      background_color,
      text_color,
    } = primary;

    return (
      <section
        className={clsx('Hero-Centered wide-container [ relative ][ w-full ]', {
          'bg-gray-0': background_color === 'dark',
          'bg-white': background_color === 'light',
          'bg-blue-95': background_color === 'light_blue'
        })}
        data-slice-type={slice_type}
        data-slice-variation={variation}
      >
        <div className="[ flex flex-col items-center justify-center ][ py-32 px-5 md:py-32 md:px-16 lg:py-40 ]">
          <div className="[ max-w-3xl text-center ]">
            <h2 className={clsx('[ text-30 md:text-46 font-medium tracking-tight leading-tight ][ mb-6 ]', {
              'text-white': text_color === 'primary_white',
              'text-gray-0': text_color === 'primary_black'
            })} data-testid="heading">
              {heading}
            </h2>
            <div className={clsx("[ mb-6 ][ text-14 md:text-20 ]", {
              '[&_p]:text-white': text_color === 'primary_white',
              '[&_p]:text-gray-0': text_color === 'primary_black'
            })}>
              <PrismicRichText
                field={description}
                applyStyles={false}
                boldHighlight="primary"
              />
            </div>
            {primary_cta_text && (
              <CommonButton
                color="blue"
                size="medium"
                href={asLink(primary_cta_url)}
                target={primary_cta_target}
                className="[ w-full md:w-auto ]"
              >
                {primary_cta_text}
              </CommonButton>
            )}
          </div>
        </div>
      </section>
    );
  }

  if (variation === 'assets') {
    const { heading, sub_heading, text_color, background_color } = primary;

    return (
      <section
        className={clsx('Hero-Assets wide-container', {
          'bg-gray-0': background_color === 'dark',
          'bg-white': background_color === 'light',
          'bg-blue-95': background_color === 'light_blue'
        })}
        data-slice-type={slice_type}
        data-slice-variation={variation}
      >
        <div className="[ py-32 px-5 md:py-32 md:px-16 lg:py-40 ]">
          <header>
            <h1 className={clsx("[ text-30 md:text-60 font-medium tracking-tight leading-tight ][ mb-6 ]", {
              'text-white': text_color === 'primary_white',
              'text-gray-0': text_color === 'primary_black',
              'text-gray-60': text_color === 'secondary_white',
              'text-gray-40': text_color === 'secondary_black'
            })} data-testid="heading">
              {heading}
            </h1>
            {sub_heading && (
              <div className={clsx("[ text-14 md:text-20 ]", {
                'text-white': text_color === 'primary_white',
                'text-gray-0': text_color === 'primary_black',
                'text-gray-60': text_color === 'secondary_white',
                'text-gray-40': text_color === 'secondary_black'
              })}>{sub_heading}</div>
            )}
          </header>

          {(items || []).map((item, index) => {
            const {
              id,
              title,
              url,
              logo,
              dark_logo,
              cover,
              description,
              link_text = 'Read more',
              published_date,
            } = (item?.news as any)?.data || {};
            return (
              <div key={id} className="[ grid grid-cols-1 lg:grid-cols-2 ][ rounded-lg ][ mt-20 ][ bg-gray-10 ]">
                <div className="[ p-10 ]">
                  {published_date && (
                    <div className={clsx("[ text-16 font-medium ][ mb-6 ]", {
                      'text-white': text_color === 'primary_white',
                      'text-gray-0': text_color === 'primary_black'
                    })}>
                      {published_date}
                    </div>
                  )}
                  <div>
                    {background_color === 'dark' ? (
                      <PrismicNextImage field={logo} height={24} />
                    ) : (
                      <PrismicNextImage field={dark_logo} height={24} />
                    )}
                  </div>
                  <div className={clsx("[ text-26 md:text-24 font-medium ][ my-6 ]", {
                    'text-white': text_color === 'primary_white',
                    'text-gray-10': text_color === 'primary_black'
                  })}>{title}</div>
                  <div className={clsx("[ text-14 md:text-20 ][ mb-6 ]", {
                    'text-white': text_color === 'primary_white',
                    'text-gray-10': text_color === 'primary_black'
                  })}>
                    {description}
                  </div>
                  <div>
                    <CtaLink
                      href={asLink(url)}
                      className={clsx(
                        '[ font-medium ]',
                        {
                          '[ text-white ]': text_color === 'primary_white',
                          '[ text-gray-10 ]': text_color === 'primary_black',
                        },
                      )}
                    >
                      {link_text}
                    </CtaLink>
                  </div>
                </div>
                <div className="[ overflow-hidden ][ lg:rounded-r-lg ]">
                  <PrismicNextImage field={cover} className="[ w-full h-full object-cover object-center ]" />
                </div>
              </div>
            );
          })}
        </div>
      </section>
    );
  }

  if (variation === 'blog') {
    const { heading, text_color, image, background_color } = primary;
    const published_at = format(new Date((context as any).published_at), 'dd MMM yyyy') || {};
    const readingtime = (context as any).readingtime || {};

    const author = (context as any).author || {};
    const categories = (context as any).categories || [];
    const { name: authorName, image: authorImage } = author?.data || {};
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const router = useRouter();
    const { uid } = router?.query || {};
    const  toHyphenCase = (str: string) => {
      return str
        .toLowerCase()  // Convert to lowercase
        .replace(/[^a-z0-9]+/g, '-')  // Replace non-alphanumeric characters with hyphens
        .replace(/(^-|-$)/g, '');  // Remove leading or trailing hyphens
    }

    const wordCount = heading?.trim()?.split(/\s+/)?.length;
    return (
      <section
        className={clsx('Hero-Blog wide-container [ w-full ]', {
          'bg-gray-0': background_color === 'dark',
          'bg-white': background_color === 'light',
          'bg-blue-95': background_color === 'light_blue'
        })}
        data-slice-type={slice_type}
        data-slice-variation={variation}
      >
        <div className="[ pt-8 pb-16 px-5 md:pt-12 md:pb-20 md:px-16 lg:pb-28 ]">
          <BreadCrumbs background_color={background_color} slug={toHyphenCase(heading)} />
          <div className="[ grid grid-cols-1 lg:grid-cols-2 gap-20 ]">
            <div className="[ relative ]" id="colum1-blog-article">
              <h1
                className={clsx("[ text-30 md:text-46 font-medium tracking-tight leading-snug ]", {
                  'text-white': text_color === 'primary_white',
                  'text-gray-0': text_color === 'primary_black'
                })}
                data-testid="heading"
              >
                {heading}
              </h1>
              {authorName && (
                <div className={clsx("[ mt-9 lg:mt-12 ]", {
                  'absolute bottom-0': wordCount <= 6
                })}>
                  <div className="[ flex items-center gap-4 mb-6 ]">
                    {asImageSrc(authorImage) && !authorImage.url.includes(FIXED_AUTHOR_IMG_FILE_NAME) && (
                      <div className="[ w-16 h-16 ]">
                        <PrismicNextImage field={authorImage} className="[ rounded-full ][ w-full ]" />
                      </div>
                    )}

                    <div>
                      <div className={clsx("[ text-12 font-semibold ]", {
                        'text-white': text_color === 'primary_white',
                        'text-gray-10': text_color === 'primary_black'
                      })}>
                        {authorName}
                      </div>
                      {published_at && (
                        <div className={clsx("[ text-12 mt-2 ]", {
                          'text-white': text_color === 'primary_white',
                          'text-gray-0': text_color === 'primary_black'
                        })}>
                          <span>
                            {published_at}
                            <span className="[ mx-2 ]">&bull;</span>
                            {readingtime} mins read
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="[ flex gap-3 ]">
                    {categories &&
                      categories.map((category, index) => {
                        const { sub_heading } = category?.category?.data || {};
                        if (sub_heading) {
                          return (
                            <div key={index} className={clsx("[ px-2 py-0.5 rounded ]", {
                              'bg-gray-10': background_color === 'dark',
                              'bg-gray-90': background_color === 'light'
                            })}>
                              <span className={clsx("[ text-12 ]", {
                                'text-white': text_color === 'primary_white',
                                'text-gray-0': text_color === 'primary_black'
                              })}>
                                {sub_heading}
                              </span>
                            </div>
                          );
                        }
                        return null;
                      })}
                  </div>
                </div>
              )}
            </div>
            <div>
              <div className="[ rounded-lg overflow-hidden ]">
                <PrismicNextImage field={image} data-elastic-name="primary_image" className="[ w-full ]" />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

  if (variation === 'featureCard') {
    const { pre_heading, heading, sub_heading, text_color, background_color, related_blogs } = primary;
    const pageRelatedBlogs = JSON.parse(related_blogs);
    const unifiedBlogs = normalizeBlogs(pageRelatedBlogs, items);
    return (
      <section
        className={clsx('Hero-FeatureCard wide-container [ w-full ]', {
          'bg-gray-0': background_color === 'dark',
          'bg-white': background_color === 'light',
          'bg-blue-95': background_color === 'light_blue'
        })}
        data-slice-type={slice_type}
        data-slice-variation={variation}
      >
        <div className="[ py-20 px-5 md:px-16 lg:py-24 ]">
          <header>
            {pre_heading && (
              <div className="[ flex gap-2 ]">
                <span className={clsx("[ px-2 py-1 rounded text-13 ]", {
                'text-white': text_color === 'primary_white',
                'text-gray-0': text_color === 'primary_black'
              })}>
                  {pre_heading}
                </span>
              </div>
            )}
            <h1 className={clsx("[ text-46 font-medium tracking-tight ][ mb-6 ]", {
              'text-white': text_color === 'primary_white',
              'text-gray-0': text_color === 'primary_black'
            })} data-testid="heading">
              {heading}
            </h1>
            {sub_heading && (
              <div className={clsx("[ text-20 ]", {
                'text-white': text_color === 'primary_white',
                'text-gray-0': text_color === 'primary_black'
              })}>{sub_heading}</div>
            )}
          </header>
          <div className="[ grid grid-cols-1 lg:grid-cols-2 gap-16 ][ mt-16 ]">
            {unifiedBlogs[0] && (
              <Link key={index} href={unifiedBlogs[0]?.url || ''} unstyled>
                <div className="[ flex flex-col cursor-pointer ]">
                  <div className="[ overflow-hidden rounded-lg ][ mb-4 ][ h-52 md:h-108 ]">
                    <div
                      className="[ w-full h-full ][ bg-center bg-cover bg-no-repeat ][ transition-transform duration-300 hover:scale-105 ]"
                      style={{
                        backgroundImage: `url(${unifiedBlogs[0]?.imageUrl || ''})`,
                      }}
                    ></div>
                  </div>
                  <>
                    {unifiedBlogs[0] && unifiedBlogs[0].tags && unifiedBlogs[0].tags.length > 0 && (
                      <div className="[ flex gap-2 ]">
                        {unifiedBlogs[0].tags.map((tag, index) => {
                          if (tag) {
                            return (
                              <span key={index} className={clsx("[ px-2 py-1 ][ rounded ][ text-13 ]", {
                                'text-white bg-gray-30': text_color === 'primary_white',
                                'text-gray-0 bg-gray-90': text_color === 'primary_black'
                                
                              })}>
                                {tag?.tag || ''}
                              </span>
                            );
                          }
                          return null;
                        })}
                      </div>
                    )}
                    <div className={clsx("[ text-24 md:text-28 font-medium ][ my-4 ]", {
                      'text-white': text_color === 'primary_white',
                      'text-gray-10': text_color === 'primary_black'
                    })}>
                      {unifiedBlogs[0]?.title || ''}
                    </div>
                    <Link
                      className="[ text-14 font-medium ][ hover:text-blue-50 transition-colors ]"
                      endIcon={<ChevronRight fill={background_color === 'light' ? 'black' : 'white'} />}
                      type="emphasis"
                    >
                      {unifiedBlogs[0].ctaText || 'Read more'}
                    </Link>
                  </>
                  <div className="[ h-0.5 ][ my-8 ][ bg-gray-40 ][ lg:hidden ]" />
                </div>
              </Link>
            )}
            <div>
              {(unifiedBlogs || []).slice(1).map((item, index) => {
                const { title, ctaText, tags, url } = item;
                if (title) {
                  return (
                    <div key={title}>
                      <div className="[ cursor-pointer ]">
                        {tags && tags.length > 0 && (
                          <div className="[ flex gap-2 ]">
                            {tags.map((tag, index) => {
                              if (tag) {
                                return (
                                  <span key={index} className={clsx("[ px-2 py-1 ][ rounded ][ text-13 ]", {
                                    'text-white bg-gray-30': text_color === 'primary_white',
                                    'text-gray-0 bg-gray-90': text_color === 'primary_black'
                                    
                                  })}>
                                    {tag?.tag || ''}
                                  </span>
                                );
                              }
                              return null;
                            })}
                          </div>
                        )}
                        <Link key={index} href={url} className="[ [&_span]:hover:text-blue-50 [&_path]:hover:fill-blue-50 [&_svg]:hover:translate-x-1 ]" unstyled>
                          <div className={clsx("[ text-24 md:text-28 font-medium ][ mt-2 mb-4 ]", {
                            'text-white': text_color === 'primary_white',
                            'text-gray-10': text_color === 'primary_black'
                          })}>
                            {title || ''}
                          </div>
                          <Link
                            className="[ flex items-center ][ font-medium ][ transition [&_path]:transition [&_svg]:transition ]"
                            endIcon={<ChevronRight fill={background_color === 'light' ? 'black' : 'white'} />}
                            type="emphasis"
                            unstyled
                          >
                            {ctaText || 'Read more'}
                          </Link>
                        </Link>
                      </div>
                      {unifiedBlogs.length - 2 !== index && <div className="[ my-8 ][ h-0.5 ][ bg-gray-40 ]" />}
                    </div>
                  );
                }
                return null;
              })}
            </div>
          </div>
        </div>
      </section>
    );
  }

  if (variation === 'resource') {
    const { heading, text_color, background_color, background_image, author, categories, published_date, read_time } =
      primary;
    const { name: authorName, image: authorImage } = (author as any)?.data || {};
    return (
      <section
        className={clsx("Hero-Resource wide-container [ w-full ][ bg-cover bg-center bg-no-repeat ]", {
          'bg-gray-0': background_color === 'dark',
          'bg-white': background_color === 'light', 
          'bg-blue-95': background_color === 'light_blue'
        })}
        data-slice-type={slice_type}
        data-slice-variation={variation}
        style={{ backgroundImage: `url("${background_image.url}")` }}
      >
        <div className="[ py-32 px-5 md:py-32 md:px-16 lg:py-40 ]">
          <div className="[ flex justify-center items-center ]">
            <div className="[ max-w-xs md:max-w-3xl ]">
              <h1
                className={clsx("[ text-30 md:text-46 font-medium tracking-tight leading-tight text-center ]", {
                  'text-white': text_color === 'primary_white',
                  'text-gray-0': text_color === 'primary_black'
                })}
                data-testid="heading"
              >
                {heading}
              </h1>
              <div className="[ mt-9 md:mt-8 ]">
                {authorName && (
                  <div className="[ flex justify-center items-center gap-4 mb-6 ]">
                    <div className="[ w-12 h-12 ]">
                      <PrismicNextImage field={authorImage} className="[ rounded-full border border-white ]" />
                    </div>
                    <div>
                      <div className={clsx("[ text-13 font-semibold ]", {
                        'text-white': text_color === 'primary_white',
                        'text-gray-0': text_color === 'primary_black'
                      })}>
                        {authorName}
                      </div>
                      {published_date && (
                        <div className={clsx("[ text-13 ]", {
                          'text-white': text_color === 'primary_white',
                          'text-gray-0': text_color === 'primary_black'
                        })}>
                          <span>
                            {published_date} &bull; {read_time}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {!authorName && published_date && (
                  <div className={clsx("[ text-13 text-center ][ mb-6 ]", {
                    'text-white': text_color === 'primary_white',
                    'text-gray-0': text_color === 'primary_black'
                  })}>
                    <span>
                      {published_date} &bull; {read_time}
                    </span>
                  </div>
                )}
              </div>

              <div className="[ flex justify-center items-center gap-3 ]">
                {categories &&
                  categories.split(',').map((cat: string, index) => (
                    <div key={index} className={clsx("[ px-2 py-2 rounded ]", {
                      'bg-gray-10': background_color === 'dark',
                      'bg-gray-90': background_color === 'light'
                    })}>
                      <span className={clsx("[ text-13 ]", {
                        'text-white': text_color === 'primary_white',
                        'text-gray-0': text_color === 'primary_black'
                      })}>{cat}</span>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

  return <HeroLight slice={slice} index={index} slices={slices} context={context} />;
};

const BreadCrumbs = ({ background_color, slug }) => {
  const trimSlug = slug => {
    if (slug && slug.length > 15) {
      return slug || `${slug.substring(0, 15)} ...`;
    }
    return slug;
  };
  useEffect(() => {
    const handleBreadcrumbSize = () => {
      const col1 = document.getElementById('colum1-blog-article');
      const blink1 = document.getElementById('breadcrumb-link-1');
      const blink2 = document.getElementById('breadcrumb-link-2');
      document.getElementById('breadCrumbs-wrapper').style.width = `${col1.offsetWidth}px`;
      // Now assign the width to last element means slug
      const slug = document.getElementById('breadcrumbs-slug');
      const col1Width = col1.offsetWidth;
      const width = col1.offsetWidth - blink1.offsetWidth - blink2.offsetWidth - 38;
      slug.style.width = `${width}px`;
    };
    handleBreadcrumbSize();
    window.addEventListener('resize', handleBreadcrumbSize);
    () => window.removeEventListener('resize', () => null);
  }, []);

  // const
  return (
    <div className="[ flex gap-1 ][ mb-16 ][ w-fit ]" id="breadCrumbs-wrapper">
      <a
        className={clsx('[ flex items-center gap-1 ][ transition-all hover:underline ]', {
          'text-white': background_color === 'dark',
          'text-gray-0': background_color === 'light'
        })}
        href={'/'}
        target={'_self'}
        id="breadcrumb-link-1"
      >
        <SVGLibrary name={'Building'} fill={background_color === 'dark' ? 'white' : 'black'} />
        <span className="[ pt-1 ][ overflow-hidden ][ text-nowrap text-ellipsis ]">Home</span>
      </a>
      <a
        className={clsx('[ flex items-center gap-1 ][ transition-all hover:underline ]', {
          'text-white': background_color === 'dark',
          'text-gray-0': background_color === 'light'
        })}
        href={'/blog'}
        target={'_self'}
        id="breadcrumb-link-2"
      >
        <SVGLibrary name={'LinkForwardArrow'} fill={background_color === 'dark' ? 'white' : 'black'} />
        <span className="[ pt-1 ][ overflow-hidden ][ text-nowrap text-ellipsis ]">Blog</span>
      </a>
      <div
        className={clsx('[ flex items-center gap-1 ]', {
          'text-white': background_color === 'dark',
          'text-gray-10': background_color === 'light'
        })}
        onClick={e => e.preventDefault()}
      >
        <SVGLibrary name={'LinkForwardArrow'} fill={background_color === 'dark' ? 'white' : 'black'} />
        <span id="breadcrumbs-slug" className="[ pt-1 ][ overflow-hidden ][ text-nowrap text-ellipsis ]">{trimSlug(slug)}</span>
      </div>
    </div>
  );
};

export default Hero;
