import { ErrorMessage } from '@hookform/error-message';
import type * as prismic from '@prismicio/client';
import { asImageSrc, asLink } from '@prismicio/client';
import { PrismicNextImage } from '@prismicio/next';
import { SliceComponentProps } from '@prismicio/react';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { HeroSliceDefault, HeroSliceMinimal } from 'ui/../prismic/prismicio-types';
import { Button, ButtonAppearance } from 'ui/components/Button';
import { PrismicRichText } from 'ui/components/PrismicRichText';
import { VideoModalButton } from 'ui/components/VideoModalButton';
import VideoModal from 'ui/components/VideoModel/VideoModel';
import TrustedCompaniesSection from 'ui/sections/TrustedCompaniesSection';
import { HttpService } from 'ui/services/http';
import { mxServerFunction } from 'ui/services/mxServerFunction';
import { SVGLibrary } from 'ui/svgs/library';
import { handleEmailSubmit } from 'ui/utils/helpers/email-submission-helper';
import styles from './HeroLight.module.scss';

export type HeroProps = SliceComponentProps<prismic.SharedSlice<'hero', HeroSliceDefault | HeroSliceMinimal>>;

const HeroVideo = ({ id, children }: PropsWithChildren<{ id: string }>): JSX.Element => {
  return (
    <div className={styles.heroVideo}>
      {children}
      <VideoModalButton
        watchVideoBtn={{
          title: '',
          videoId: id,
        }}
        className={styles.heroVideoButton}
      />
    </div>
  );
};

const HeroLight = ({ slice }: HeroProps): JSX.Element => {
  const logos = (slice.primary.logos as any)?.data?.logos as any[];
  const { primary, slice_type, variation } = slice;

  const {
    trial_text,
    description,
    title,
    title_size,
    caption_text,
    caption_link,
    caption_link_text,
    primary_button,
    primary_button_link,
    secondary_button,
    secondary_button_link,
    logos_tagline,
    image,
    image_size,
    spacing,
    padding_bottom,
    tagline,
    form_type,
    form_cta,
    service_name,
    svg_inline,
    youtube_video_id,
    slack_notification_enable,
    slack_notification_type,
    form_success_page,
    headshot,
    button_icon_type,
    background_color,
  } = primary;

  const formRef = useRef();
  const emailInputRef = useRef<HTMLInputElement>();
  const { query, push } = useRouter();

  const [email, setEmail] = useState<string>((query as any)?.email ?? '');
  const [primaryButton, setPrimaryButton] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isValidating, isSubmitting, isValid, isSubmitSuccessful },
    reset,
    clearErrors,
  } = useForm({
    reValidateMode: 'onSubmit',
  });
  useEffect(() => {
    reset({
      email: query?.email || '',
    });
  }, [query?.email, reset]);

  useEffect(() => {
    window.addEventListener('HeroPrimaryCtaChange', onPrimaryCtaChange);

    return () => {
      window.removeEventListener('HeroPrimaryCtaChange', onPrimaryCtaChange);
    };
  }, []);

  const onPrimaryCtaChange = event => {
    setPrimaryButton(event.detail);
  };

  const onSubmit = async () => {
    if (slack_notification_enable) {
      await HttpService.post('/api/slack', {
        email,
        url: window.location.href,
        type: slack_notification_type,
      });
    }
    const success_page = asLink(form_success_page);
    if (success_page) {
      push(success_page);
    } else if (form_type) {
      handleEmailSubmit(email, form_type, service_name);
    }
  };

  const disableButton = isValidating || isSubmitting || Object.keys(errors).length > 0;

  const renderForm = () => {
    const inputFieldName = 'email';
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    return (
      <>
        <form className={styles.form} ref={formRef} onSubmit={handleSubmit(onSubmit)}>
          <input
            className={clsx({
              [styles.error]: !!errors.email,
            })}
            ref={emailInputRef}
            type="text"
            placeholder="Your work email"
            {...register(inputFieldName, {
              required: `Please enter your ${inputFieldName}`,
              pattern: inputFieldName === 'email' && {
                value: emailRegex,
                message: 'Please enter a valid work email',
              },
              validate: {
                mxServer: mxServerFunction,
              },
              onChange: e => {
                setEmail(e.target.value);
                clearErrors();
              },
            })}
          />
          {trial_text && <div className={clsx(styles.notification, styles.trialTextOnMobile)}>{trial_text}</div>}
          <button type="submit" disabled={disableButton}>
            {(isValidating || isSubmitting || isSubmitSuccessful) && <div className={styles.loader}></div>}
            {form_cta}
          </button>
          <ErrorMessage errors={errors} name={inputFieldName} as="span" className={styles.errorMessage} />
        </form>
        {trial_text && (
          <div
            className={clsx(
              styles.notification,
              {
                [styles.trialTextOnError]: Object.keys(errors).length === 0,
              },
              styles.trialText,
            )}
          >
            {trial_text}
          </div>
        )}
      </>
    );
  };

  const renderImage = () => {
    return asImageSrc(image) ? (
      <PrismicNextImage field={image} loading="eager" priority={true} style={{ objectFit: 'contain' }} />
    ) : (
      <SVGLibrary name={(svg_inline as any) || 'Rowing'} />
    );
  };

  const isFormEnabled = form_cta && (form_type || slack_notification_enable);

  return (
    <>
      <div className={clsx(styles[`bg-${background_color ?? 'white'}`])}>
        <section
          data-slice-type={slice_type}
          data-slice-variation={variation}
          className={clsx(
            'container',
            styles.root,
            styles[`gap-${spacing}`],
            styles[`pb-${padding_bottom}`],
            styles[`root--imgsize-${image_size}`],
            styles[`root-${spacing}`],
          )}
        >
          <div className={styles.content}>
            {tagline && <h2 className={styles.tag}>{tagline}</h2>}
            <span className={clsx(styles.title, styles[`title--${title_size}`])}>
              <PrismicRichText field={title} applyStyles={false} />
            </span>

            <span className={styles.description}>
              <PrismicRichText field={description} />
            </span>

            {isFormEnabled && renderForm()}

            {variation === 'minimal' && logos && logos.length > 0 && (
              <div className={styles.coverLogosContainer}>
                {logos.map((logo, index) => (
                  <span key={index}>
                    <SVGLibrary name={logo.inline_svg} />
                  </span>
                ))}
              </div>
            )}

            <div
              className={clsx(styles.action, {
                [styles.actions]: primary_button && secondary_button,
              })}
            >
              {primaryButton ? (
                <Button id={primary_button} field={primaryButton.link} arrowIcon={variation === 'minimal'}>
                  {primaryButton.text}
                </Button>
              ) : (
                primary_button &&
                (button_icon_type === 'video' ? (
                  <Button
                    id={primary_button}
                    iconType={button_icon_type}
                    {...(button_icon_type === 'video' ? { onClick: () => setShowModal(true) } : {})}
                    arrowIcon={variation === 'minimal'}
                    className={styles.withIconButton}
                  >
                    {primary_button}
                  </Button>
                ) : (
                  <Button id={primary_button} field={primary_button_link} arrowIcon={variation === 'minimal'}>
                    {primary_button}
                  </Button>
                ))
              )}
              {secondary_button && (
                <Button id={secondary_button} outline={true} field={secondary_button_link}>
                  {secondary_button}
                </Button>
              )}
            </div>
            {caption_text && (
              <div
                className={clsx(styles.sub, {
                  [styles.subMarginTopSm]: logos,
                })}
              >
                <span className={styles.subText}>{caption_text}</span>
                <Button
                  href={caption_link as string}
                  appearance={ButtonAppearance.Link}
                  className={styles.subLinkText}
                  id="hero-caption-link"
                >
                  {caption_link_text}
                </Button>
              </div>
            )}
          </div>
          <div
            className={clsx(styles.image, {
              [styles.imageHasVideo]: !!youtube_video_id,
              [styles.headshot]: headshot,
            })}
          >
            {youtube_video_id && button_icon_type !== 'video' ? (
              <HeroVideo id={youtube_video_id}>{renderImage()}</HeroVideo>
            ) : (
              renderImage()
            )}
          </div>
        </section>
      </div>
      {variation !== 'minimal' && logos && (
        <TrustedCompaniesSection
          data={{
            headline: logos_tagline as string,
            desktopLogos: logos.map((logo, index) => <SVGLibrary key={`logo-${index}`} name={logo.inline_svg} />),
            titleColor: 'black',
          }}
        />
      )}
      {showModal && (
        <VideoModal
          slide={{
            video_url: youtube_video_id,
          }}
          showThumbnail={false}
          showModal={showModal}
          setShowModal={setShowModal}
        />
      )}
    </>
  );

  return null;
};

export default HeroLight;
